const state = {
	availableRooms: null,
    selectedDate: null,
    selectedPax: null,
    selectedRoom: null,
    selectedTime: null,
	selectedReservationDuration: null,
	timePickerObject: null,
	allTimeSlots: null,
	enableTimeSlots: null,
	rooms: []
};

const getters = {
	rooms: state =>{
		return state.rooms
	},
	isInputTimeDisable: state => {
		return state.selectedDate && state.selectedPax && state.selectedRoom;
	},
	isFormReservationReady: state => {
		return state.selectedDate && state.selectedPax && state.selectedRoom && state.selectedTime;
	},
	enableTimeSlots: state => {
		return state.enableTimeSlots;
	},
};

const mutations = {
	setRooms(state, payload) {
		state.rooms = payload.rooms;
	},
    setAvailableRooms(state, payload) {
        state.availableRooms = payload.availableRooms;
    },
	setAllTimesSlots(state, payload) {
		state.allTimeSlots = payload.allTimeSlots;
	},
	setSelectedDate(state, payload) {
		state.selectedDate = payload.selectedDate;
	},
	setSelectedPax(state, payload) {
		state.selectedPax = payload.selectedPax;
	},
	setSelectedRoom(state, payload) {
		state.selectedRoom = payload.selectedRoom;
	},
	setSelectedTime(state, payload) {
		let duration = null;
		if (state.allTimeSlots) {
			const slot = state.allTimeSlots.find(slot => slot.timeSlot === payload.selectedTime);
			duration = slot ? slot.duration : null;
		}
		state.selectedTime = payload.selectedTime;
		state.selectedReservationDuration = duration;
		console.log('el selected time: ', state.selectedTime, ' y la duracion: ', state.selectedReservationDuration)
	},

	setTimesSlotsEnable(state, payload) {
		const timesSlotsEnable = payload.timesToEnable.map(timeSlot => timeSlot.time);
		let isCurrentTime = false;
		const oldSelectedTime = state.selectedTime;
		if(oldSelectedTime){
			const oldSelectedTimeFormated = oldSelectedTime.split(':').map(str => parseInt(str, 10));
			isCurrentTime = timesSlotsEnable.some(arr => arr[0] === oldSelectedTimeFormated[0] && arr[1] === oldSelectedTimeFormated[1]);
		}

		state.enableTimeSlots = payload.timesToEnable;
		state.timePickerObject.set('disable', true);
		state.timePickerObject.set('enable', false);
		state.timePickerObject.set('disable', timesSlotsEnable);

		if(isCurrentTime){
			let duration = null
			state.selectedTime = oldSelectedTime;
			const slot = state.allTimeSlots.find(slot => slot.timeSlot === state.selectedTime);
			duration = slot ? slot.duration : null;
			state.selectedReservationDuration = duration;
		}else{
			state.selectedTime = null
		}
	},
	setTimePickerObjetc(state, payload) {
		state.timePickerObject = payload.timePickerObject;
	},
	clearData(state){
		state.availableRooms = null;
		state.selectedDate = null;
		state.selectedPax = null;
		state.selectedRoom = null;
		state.selectedTime = null;
	}
};

export default {
    namespaced: true,
    state,
    mutations,
	getters,
};
