import {sendRequestJson, sendRequest} from '@/utils'
import {cookieManager} from "@/service/cookieManager";

export class ReservationRepository {

	constructor() {

		let host = window.location.hostname;
		if (host === "localhost"){
			this.urlBase = 'https://dev.wepass.ai';
		} else {
			this.urlBase = window.location.origin;
		}
	}

	async find(reservationId) {
		const data = {reservationId}
		const url = this.urlBase + "/world/api/reservation/reservationinfo"
		console.log("Me gustaría traerme la info de la reserva de ");
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la informacion de la reserva.");
		}
	}

	async create(data) {
		const url = this.urlBase + "/world/reservation/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando la reserva.");
		}
	}

	async update({client, data}) {
		const url = `https://${client}.wewelcom.io` + "/world/api/reservation/updatereservation";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando la reserva.");
		}
	}

	async getAvailableRooms(client) {
		const url = `https://${client}.wewelcom.io` + "/world/reservation/availablerooms";
		const roomResponse = await sendRequest({data: {}, url, method:"POST"});
		return roomResponse.rooms;
	}

	async checkAvailability(data, client) {
		const url = `https://${client}.wewelcom.io` + "/world/reservation/checkavailabilitytoupdate";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal comprobando la disponibilidad.");
		}
	}

	async cancelReservation(reservationId, client) {
		const url = `https://${client}.wewelcom.io` + "/world/reservation/canceldirect";
		const data = {
			"reservationId": reservationId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando reserva por email");
		}
	}

	async cancelAssistanceReservation(reservationId, userId) {
		const url = this.urlBase + "/world/reservation/removeinvitee";
		const data = {
			"reservationId": reservationId,
			"userId": userId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando la cancelacion de la asistencia");
		}
	}

	async addInvitedUser(reservationWid, userId, enableMenu = 0) {
		const url = this.urlBase + `/world/api/reservation/adduser?enableMenu=${enableMenu}`
		const data ={
			reservationWid, userId
		}
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion.");
		}
	}

	async addUnknownInvitedUser(reservationWid, hostCode, invitedUserName) {
		const url = this.urlBase + "/world/api/reservation/addguest"
		try {
			let data = {
				createGuest: true,
				reservationWid,
				hostCode,
				invitedUserName
			}
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion de usuario desconocido.");
		}
	}

	async enableReservation(data) {
		const url = this.urlBase + "/world/api/reservation/enable-reservation"
		try {
			const token = cookieManager.getToken();
			return await sendRequestJson({data, url, method:"POST", token});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}


}

export const reservationRepository = new ReservationRepository();
